<template>
    <v-container fluid>
        <v-form @submit.prevent="saveCrewMember">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'crew_member'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="crew_member" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="crew_member" type="text" :disabled="loading"
                                                  :error="!valid" :error-messages="errors"
                                                  prepend-icon="mdi-gender-male-female"
                                                  :label="$t('crew_member_name')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="symptom_category" rules="required" v-slot="{ errors, valid }">
                                    <v-select v-model="type_crew_member" :disabled="loading" :items="typeCrewMemberItems"
                                              prepend-icon="mdi-account-supervisor"
                                              :error="!valid" :error-messages="errors"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('type_crew_member')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <v-subheader class="pt-0 mb-2 font-weight-medium">
                                    {{ $t('description') }}
                                </v-subheader>
                                <ValidationProvider ref="description"
                                                    rules="required|min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <tinymce-editor id="description" v-model="description"
                                                    :init="tinymceInit"></tinymce-editor>
                                    <div v-show="!valid" class="pt-0 mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('description_is_not_filled_out') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
    name: 'CrewMemberForm',
    components: {
        TinymceEditor,
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            crew_member: null,
            description: null,
            type_crew_member: null,
            typeCrewMemberItems: [],
            exist_translations: {},
            all_translations: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getTypeCrewMembers();
        await this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "crew_member.create") {
                this.heading = this.$t('crew_member_creation')
            } else {
                this.heading = this.$t('crew_member_editing')
                if (this.$route.params.id) {
                    this.getCrewMember()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getTypeCrewMembers() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/type_crew_member", {
                    params: params,
                })
                .then(res => {
                    this.typeCrewMemberItems = res.body.data
                })
                .catch(err => {
                    this.typeCrewMemberItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getCrewMember() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/crew_member/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.crew_member = res.body.data.name
                    this.description = res.body.data.description
                    this.type_crew_member = res.body.data.type_crew_member_id
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_crew_member'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveCrewMember() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.crew_member) {
                formData.append('name', this.crew_member)
            }
            if (this.description) {
                formData.append('description', this.description)
            }
            if (this.type_crew_member) {
                if (this.type_crew_member.id) {
                    formData.append('type_crew_member_id', this.type_crew_member.id)
                } else {
                    formData.append('type_crew_member_id', this.type_crew_member)
                }
            }
            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/crew_member/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('crew_member_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('crew_member_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/crew_member', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('crew_member_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'crew_member.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'crew_member'
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('crew_member_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
